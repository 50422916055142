html, body, #root {height: 100%}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Standard */

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #353d45;
}

li {
  font-size: 14.5px;
  line-height: 22px;
  list-style: none;
  padding-top: 3px;
}

/* Structure */

.container {
  display: grid;
  grid-template-areas:
    ". header header ."
    ". sidebar content ."
    ". footer footer .";
  grid-template-columns: 1fr 220px 840px 1fr;
  grid-template-rows: 65px 1fr 100px;
}

/* Header */

header {
  cursor: default;
  display: grid;
  grid-area: header;
  grid-template-areas: ". display-center .";
  grid-template-columns: 10px 1fr 10px;
  margin: 0;
  padding: 0;

  height: 0px;
  top: 0;
  position: sticky;
  position: -webkit-sticky; /* Required for Safari */
}

header .display-center {
  border-bottom: 0.1px solid #d9d9d9;
  grid-area: display-center;
  margin-left: 35px;
  margin-right: 60px;
  padding: 0px;
  background: white;
  opacity: 0.95;
}

/* Sidebar */

.sidebar {
  color: #73787e;
  grid-area: sidebar;
  padding-top: 30px;
  /*padding-top: 96px;
    padding-left: 25px;*/

  height: 0px;
  top: 4rem;
  position: sticky;
  position: -webkit-sticky; /* Required for Safari */
}

.sidebar a li {
  padding: 2px 11px;
}

.sidebar a li:hover {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
}

.sidebar .list-header {
  color: #73787e;
  cursor: pointer;
  /* font-weight: 500; */
  line-height: 1rem;
  padding: 5px 5px 5px 7px;
  user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.sidebar .list-header-no-icon {
  color: #73787e;
  cursor: pointer;
  /* font-weight: 500; */
  line-height: 1rem;
  padding: 5px 5px 5px 21px;
  user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.sidebar .list-header:hover {
  background-color: rgb(242, 242, 242);
  border-radius: 8px;
}

.sidebar .list-header-selected {
  color: #141c25;
  cursor: pointer;
  font-weight: 500;
  line-height: 1rem;
  padding: 5px 5px 5px 7px;
}

.sidebar .list-header-selected:hover {
  background-color: rgb(242, 242, 242);
  border-radius: 8px;
}

.sidebar .nested-list-hidden {
  display: none;
}

.sidebar .nested-list:not(:first-child) {
  padding-bottom: 12px;
  padding-left: 10px;
}

/* Content */

h1,
h2,
h3 {
  color: #141c25;
  margin: 0;
}

h1,
h2 {
  font-weight: 700;
}

h1 {
  font-size: 36px;
  line-height: 40px;
  padding-bottom: 20px;
}

h2 {
  font-size: 28px;
  line-height: 32px;
  padding: 16px 0;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  padding: 4px 0;
}

p {
  color: #73787e;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  padding-bottom: 20px;
}

p span {
  border: 1px solid rgb(229, 229, 234);
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 16px;
}

b {
  font-weight: bold;
}

section {
  grid-area: content;
  padding: 45px 80px 50px 50px;
}

strong {
  color: #5c9cf5;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
}

/* Clip */

.clip video {
  padding-top: 10px;
  text-align: center;
  width: 100%;
}

.clip li {
  cursor: pointer;
  display: inline;
}

.clip img {
  height: 16px;
  padding-right: 4px;
  vertical-align: middle;
}

.clip span {
  color: #5c9cf5;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}

/* Others */

.center {
  display: block;
  padding-top: 10px;
  padding-bottom: 40px;
  width: 100%;
}

.logo {
  float: left;
  height: 25px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.arrow-down {
  width: 10px;
}

.arrow-right {
  width: 10px;
  transform: rotate(270deg);
}

.chart {
  height: 12px;
  padding-right: 6px;
}

.video {
  padding: 0 0 40px 0;
  text-align: center;
}

.video-text {
  display: inline;
  cursor: pointer;
}

.video-button {
  padding-right: 4px;
  height: 16px;
  vertical-align: middle;
}

.video-xyz {
  color: #5c9cf5;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}


/* Guide styles */

.guide-container {
  max-width: 1060px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.guide-container h2 {
  border-bottom: 1px solid #aaa;
}

.guide-container .back-link {
  padding: 12px 0;
  margin: 0 0 20px;
}

.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background-color: #34495e;
}
.home-container > img {
  width: 45px;
  height: 20px;
  margin: 24px 0 84px;
}

.home {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 12px;
}

.home a {
  /* width: 360px; */
  height: 420px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 10%;
  background-color: #ddd;
}
.home h1 {text-align: center;}
.home p {
  max-width: 300px;
  text-align: justify;
  color: #555;
}
.home a img {
  max-height: 320px;
  margin-top: -160px;
}

.home a:hover {
  background-color: #eee;
}

.home a:first-child {
  margin-right: 12px;
}

.guide-list {
  padding-top: 60px;
}

.video-list__item {
  max-width: 800px;
  display: flex;
  align-items: center;
  margin: 12px auto 0;
  padding: 12px 18px;
  border-radius: 12px;
  background-color: #f1f1f1;
}

.video-list__item:hover {
  background-color: #eaeaea;
}

.video-list__item__img {
  max-width: 240px;
  max-height: 120px;
}

.video-list__item__info {
  margin-left: 24px;
}

.guide-detail {
  margin-top: 60px;
  padding: 0 0 120px;
}

.guide-detail .tabs {
  display: flex;
  align-items: center;
  height: 48px;
  margin: 24px 0;
  border-bottom: 1px solid #aaa;
}

.guide-detail .tabs span {
  margin-right: 24px;
  cursor: pointer;
}

.guide-detail .tab-selector.active {
  font-weight: bold;
}
.guide-detail .tab-content {
  display: none;
}
.guide-detail .tab-content.active {
  display: block;
}

.guide-detail li {
  list-style-type: disc;
}

.guide-detail li a {
  font-size: 0.75em;
  color: blue;
}